<template>
  <v-container class="my-4">
    <h1 class="text-h3">Cette fonctionnalité n'est pas encore implémentée</h1>
    <p class="body-1">
      Désolé, la page que vous recherchez n'est pas encore implémentée...
    </p>
    <v-btn link :to="{ name: 'home' }" color="primary"> Page d'accueil </v-btn>
  </v-container>
</template>

<script>
export default {
  name: "NotImplemented",
};
</script>

<style scoped></style>
